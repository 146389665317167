import {MaybePromise} from '@wspsoft/underscore';
import {I18n} from './i18n';
import {KolibriEntity} from './kolibri-entity';
import {Report} from './report';
import {User} from './user';

export class EmailMessage extends KolibriEntity {
  public accountId?: string;
  public accountEntityClass?: string;
  public subject?: I18n;
  public body?: I18n;
  public bodyData?: MaybePromise<I18n>;
  public entityId?: string;
  public entityName?: string;
  public deliveryStatus?: DeliveryStatus;
  public sendMeta?: {
    deliveryStatusForRecipients?: { [email: string]: DeliveryStatus };
    attachments?: string[];
    recipients?: string[];
    cc?: string[];
  };
  public receiverTo?: string;
  public cc?: string;
  public senderFrom?: string;
  public retries?: number;
  public html?: boolean;
  public priority?: EmailPriority;
  public headers?: EmailHeaders;
  public report?: MaybePromise<Report>;
  public reportId?: string;
  public executingUser?: MaybePromise<User>;
  public executingUserId?: string;
}

export enum DeliveryStatus {
  pending = 'pending', sent = 'sent', failed = 'failed', received = 'received', partial = 'partial', inactive = 'inactive', processed = 'processed'
}

export enum EmailPriority {
  low = 'low', normal = 'normal', high = 'high'
}

export interface EmailHeaders {
  'Message-ID'?: string;
  'In-Reply-To'?: string;
  'References'?: string[];

  [key: string]: any;
}
