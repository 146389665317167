import {ChangeDetectorRef, Directive, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {
  ApplicationModel,
  CriteriaQuery,
  Field,
  KolibriEntity,
  OneTableState
} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {LazyLoadEvent, SortMeta, TableState} from 'primeng/api';
import {Subscription} from 'rxjs';
import * as uuidv4 from 'uuid-random';
import {UserService} from '../../../api';
import {DatatableColumn, DatatableComponent} from '../components/structure/datatable/datatable/datatable.component';
import {MultiTabSynchronizerService} from '../service/multi-tab-synchronizer.service';
import {ShareableEntityService} from '../service/shareableEntity.service';
import {PrefQueryableComponent} from './pref-queryable-component';

export interface IListComponent<T extends any> {
  load($event: LazyLoadEvent): Promise<any[]>;

  delete(element: T): Promise<void>;

  update(element: T): Promise<void>;

  create(cb: () => void): Promise<void>;

  viewEntry($event: MouseEvent, selected: T): Promise<void>;

  generateQuery($event: LazyLoadEvent): Promise<CriteriaQuery<KolibriEntity>>;

  doBulkLoadOfFields?(value: KolibriEntity[], fields?: string[]): Promise<void>;

  updateTable(table: DatatableComponent): void;
}

@Directive()
export abstract class ListComponent<T extends any> extends PrefQueryableComponent implements OnDestroy, IListComponent<T> {
  @Input()
  public application: ApplicationModel;
  public sortMeta: SortMeta[];
  public ready: boolean = false;
  // noinspection JSUnusedGlobalSymbols
  protected cols: DatatableColumn[] = [];
  protected data: T[];
  protected canScrollToTop: boolean;
  protected readonly uniqueId = uuidv4();
  // noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols,JSMismatchedCollectionQueryUpdate
  private defaultSort: SortMeta[] = [{
    field: 'name',
    order: 1
  }];
  private routerSubscription: Subscription;

  protected constructor(protected cdr: ChangeDetectorRef, protected router: Router, protected multiTab: MultiTabSynchronizerService,
                        protected shareableEntityService: ShareableEntityService, protected route: ActivatedRoute,
                        protected userService: UserService) {
    super(router, multiTab, shareableEntityService, route, userService);
  }

  protected get tableState(): TableState {
    return JSON.parse(sessionStorage.getItem(this.id) || '{}');
  }

  public ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  public abstract create(cb: () => void): Promise<void>;

  public abstract delete(element: T): Promise<void>;

  public abstract load($event: LazyLoadEvent): Promise<KolibriEntity[]>;

  public abstract generateQuery($event: LazyLoadEvent): Promise<CriteriaQuery<KolibriEntity>>;

  public abstract update(element: T): Promise<void>;

  public abstract viewEntry($event: MouseEvent, selected: T): Promise<void>;

  public abstract updateTable(table: DatatableComponent): void;

  /**
   * saves table state in backend
   */
  public saveState(state: TableState): void {
    if (this.currentPrefQuery) {
      // @ts-ignore
      state.prefQuery = this.currentPrefQuery.criteria;
    }
    sessionStorage.setItem(this.id, JSON.stringify(state));
  }

  public filterAvailableFields(fields: Field[]): Field[] {
    return _.filter(fields, f => f.availableForQueryBuilder);
  }

  public doRefresh(externalRefresh: boolean): void {
    this.canScrollToTop = !externalRefresh;
  }

  public doAfterLoading($event?: LazyLoadEvent): void {
    this.canScrollToTop = true;
  }

  /**
   * subscript to route navigation to rerender current page
   */
  protected subscribeToRouteChanges(): void {
    // subscribe to routing on the same page to recreate the table
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.ready = false;
        this.cdr.detectChanges();
        // for lists init is too fast, and the ready flag is not able to hide the content
        setTimeout(() => this.init());
      }
    });
  }

  protected runBeforeRestore(tableState: TableState, additionalState: OneTableState & { first?: number }): void {
    // @ts-ignore prefQuery will be set in saveState and can be used as well
    const prefQuery = tableState.prefQuery;
    if (this.currentPrefQuery && prefQuery) {
      this.currentPrefQuery.payload = prefQuery;
    }
    if (additionalState.multiSortMeta || tableState.multiSortMeta) {
      this.sortMeta = additionalState.multiSortMeta || tableState.multiSortMeta;
    }
  }

  /**
   * get table state from backend and restore state
   */
  protected restoreState(additionalState: OneTableState & { first?: number } = {}, value: TableState = this.tableState): void {
    // grab state from sessionStorage and update any needed data, if additionalState is given set these values as well
    if (_.isEmpty(value) && _.isEmpty(additionalState)) {
      sessionStorage.removeItem(this.id);
    } else {
      this.runBeforeRestore(value, additionalState);
      sessionStorage.setItem(this.id, JSON.stringify({...value, ...additionalState}));
    }
  }
}
